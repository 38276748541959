import React from 'react'
import dynamic from 'next/dynamic'
import { AdditionalContent } from '../types/api-types'
import type { OpenApplicationProps } from './paragraphs/OpenApplication'

const JobSearchLink = dynamic(() => import('./paragraphs/JobSearchLink'))
const ContentBlock = dynamic(() => import('./paragraphs/ContentBlock'))
const ArticleContentBlock = dynamic(
  () => import('./paragraphs/ArticleContentBlock'),
)
const Text = dynamic(() => import('./paragraphs/Text'))
const TextAndMedia = dynamic(() => import('./paragraphs/TextAndMedia'))
const NewsBlock = dynamic(() => import('./paragraphs/NewsBlock'))
const TeaserList = dynamic(() => import('./paragraphs/TeaserList'))
const EmployerOverview = dynamic(() => import('./paragraphs/EmployerOverview'))
const LocationBlock = dynamic(() => import('./paragraphs/LocationBlock'))
const Heading = dynamic(() => import('./paragraphs/Heading'))
const LogoList = dynamic(() => import('./paragraphs/LogoList'))
const JobOverview = dynamic(() => import('./paragraphs/JobOverview'))
const JobOverview2Panes = dynamic(
  () => import('./paragraphs/JobOverview2Panes'),
)
const Newsletter = dynamic(() => import('./paragraphs/Newsletter'))

const OpenApplication = dynamic(
  () =>
    import('./paragraphs/OpenApplication') as Promise<
      React.ComponentType<OpenApplicationProps>
    >,
)

const paragraphs: {
  [key: string]: () => React.FC<any> | React.ComponentType<any>
} = {
  'paragraph--content_block_section': () => ContentBlock,
  'paragraph--article_content_block': () => ArticleContentBlock,
  'paragraph--text': () => Text,
  'paragraph--text_and_media': () => TextAndMedia,
  'paragraph--news_block': () => NewsBlock,
  'paragraph--teaser_list': () => TeaserList,
  'paragraph--employer_overview': () => EmployerOverview,
  'paragraph--location_block': () => LocationBlock,
  'paragraph--heading': () => Heading,
  'paragraph--logo_list': () => LogoList,
  'paragraph--job_overview': () => JobOverview,
  'paragraph--job_overview_two_panes': () => JobOverview2Panes,
  'paragraph--newsletter_subscription_block': () => Newsletter,
  'paragraph--open_application': () => OpenApplication,
  'paragraph--job_search_link': () => JobSearchLink,
}

type Props = {
  paragraph: any
  country: string
  additionalContent: AdditionalContent
  resource: any
}

const ParagraphMapper = ({
  paragraph,
  country,
  additionalContent,
  resource,
  ...props
}: Props) => {
  const layoutJobOverview = process.env.NEXT_PUBLIC_LAYOUT_JOB_OVERVIEW;

  // Logic to determine if the paragraph should be rendered
  if (
    layoutJobOverview === 'original' &&
    paragraph.type === 'paragraph--job_overview_two_panes'
  ) {
    return null;
  }
  if (
    layoutJobOverview !== 'original' &&
    paragraph.type === 'paragraph--job_overview'
  ) {
    return null;
  }

  console.log(paragraph.type)

  if ( Object.prototype.hasOwnProperty.call(paragraphs, paragraph.type)) {
    const ParagraphComponent = paragraphs[paragraph.type]()
    return (
      <ParagraphComponent
        paragraph={paragraph}
        country={country}
        additionalContent={additionalContent}
        resources={resource}
        {...props}
      />
    )
  }

  // Return null if the paragraph should not be rendered
  return null
}

export default ParagraphMapper
